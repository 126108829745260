import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  initiatePurchase,
  setPurchaseRequestToken,
  setRetrievedCallbackMessage,
  fetchCallbackMessageFromBin,
  fetchCallbackMessageFromService,
  setCustomCssUrl,
  setPreSelectedMonth,
  setIsTrackingAllowed
} from './merchantSlice';
import useLocalStorage from '../../hooks/useLocalStorage';
import { setBinUrl, setHostUrl } from '../../app/appSlice';

const defaultBinHost = 'https://dev-callbacks-bin.internal.tf-b2c.com';

const customer = {
  authenticationType: 'eID',
  ssn: '197812054299'
};

const merchant = {
  agreementId: '44219020',
  acquirerId: 'TeliaSoneraSE002'
};

const callback = {
  url: `${defaultBinHost}/messages`,
  token: 'a-fine-token'
};

const order = {
  orderId: '128123',
  orderItems: [
    { productId: '584214-B', description: 'socks', amount: 1000 },
    { productId: '874177-A', description: 'phone', amount: 20000 }
  ]
};

const defaultOrder = JSON.stringify(
  { customer, merchant, callback, order },
  null,
  2
);

// eslint-disable-next-line import/prefer-default-export
export function Merchant() {
  const dispatch = useDispatch();

  const [orderState, setOrderState] = useState(defaultOrder);

  // Store the secret token in localStorage to relieve the user from
  // re-entering every time after reloading the page.
  const [authToken, setAuthToken] = useLocalStorage('auth-token', '');
  const [basicCredentials, setBasicCredentials] = useLocalStorage(
    'basic-credentials',
    {}
  );
  const [secretToken, setSecretToken] = useLocalStorage('secret-token', '');
  const retrievedCallbackMessage = useSelector(
    (state) => state.merchant.retrievedCallbackMessage
  );
  const purchaseRequestToken = useSelector(
    (state) => state.merchant.purchaseRequestToken
  );

  const isTrackingAllowed = useSelector(
    (state) => state.merchant.isTrackingAllowed
  );

  const host = useSelector((state) => state.app.hostUrl);
  const binHost = useSelector((state) => state.app.binUrl);

  const onOrderChange = (event) => {
    setOrderState(event.target.value);
  };

  const onResetClicked = () => {
    setOrderState(defaultOrder);
  };

  const handleSetBasicCredentials = (event) => {
    setBasicCredentials({
      ...basicCredentials,
      [event.target.name]: event.target.value
    });
  };
  const onInitiatePurchaseClicked = async () => {
    dispatch(initiatePurchase(host, orderState, basicCredentials, authToken));
  };

  const onFetchMessageFromBinClicked = async () => {
    dispatch(setRetrievedCallbackMessage(''));
    dispatch(
      fetchCallbackMessageFromBin(binHost, secretToken, purchaseRequestToken)
    );
  };

  const onFetchMessageFromServiceClicked = async () => {
    dispatch(setRetrievedCallbackMessage(''));
    dispatch(
      fetchCallbackMessageFromService(
        host,
        purchaseRequestToken,
        basicCredentials,
        authToken
      )
    );
  };

  // eslint-disable-next-line consistent-return
  const onPreSelectedMonthChange = (e) => {
    const number = parseInt(e.target.value, 10);
    if (Number.isNaN(number)) {
      return dispatch(setPreSelectedMonth(null));
    }
    dispatch(setPreSelectedMonth(number));
  };
  const onTrackingChange = () => {
    dispatch(setIsTrackingAllowed(!isTrackingAllowed));
  };
  // eslint-disable-next-line consistent-return
  const handleSetCustomCss = (e) => {
    if (e.target.value === 'none') return dispatch(setCustomCssUrl(null));

    const href = document.getElementById(e.target.value).href;

    dispatch(setCustomCssUrl(href));
  };
  return (
    <section className="merchant">
      <h2>Merchant</h2>
      <label htmlFor="merchant-backend-host-input">Host</label>
      <input
        type="text"
        id="merchant-backend-host-input"
        name="merchant-backend-host-input"
        placeholder="Enter a hostname"
        value={host}
        onChange={(event) => dispatch(setHostUrl(event.target.value))}
      />
      <section className="auth">
        <label>
          Basic auth - Passing in a token will take precedence over
          username/password
        </label>
        <div>
          <div>
            <label>Username</label>
            <input
              type="text"
              id="merchant-auth-username"
              name="username"
              placeholder="username"
              value={basicCredentials?.username}
              onChange={handleSetBasicCredentials}
            />
          </div>
          <div>
            <label>Password</label>
            <input
              type="password"
              id="merchant-auth-password"
              name="password"
              placeholder="password"
              value={basicCredentials?.password}
              onChange={handleSetBasicCredentials}
            />
          </div>
        </div>
        <label>Token (include prefix e.g. Basic/Bearer)</label>
        <input
          type="text"
          id="merchant-auth-token"
          name="merchant-auth-token"
          placeholder="token"
          value={authToken}
          onChange={(event) => setAuthToken(event.target.value)}
        />
      </section>

      <div className="custom-theme">
        <div>
          <fieldset onChange={handleSetCustomCss}>
            <legend>Select theme</legend>

            <div style={{ display: 'flex' }}>
              <input type="radio" value="none" name="theme" />
              <label htmlFor="none">None</label>
            </div>
            <div style={{ display: 'flex' }}>
              <input type="radio" value="halebop" name="theme" />
              <label htmlFor="halebop">Halebop</label>
            </div>
            <div style={{ display: 'flex' }}>
              <input type="radio" value="halebopOld" name="theme" />
              <label htmlFor="halebopOld">Halebop Old</label>
            </div>
            <div style={{ display: 'flex' }}>
              <input type="radio" value="halebopPurpur" name="theme" />
              <label htmlFor="halebopPurpur">Halebop Purpur</label>
            </div>
            <div style={{ display: 'flex' }}>
              <input type="radio" value="oneCall" name="theme" />
              <label htmlFor="oneCall">OneCall</label>
            </div>
            <div style={{ display: 'flex' }}>
              <input type="radio" value="oneCallOld" name="theme" />
              <label htmlFor="oneCallOld">OneCall Old</label>
            </div>
            <div style={{ display: 'flex' }}>
              <input type="radio" value="oneCallPurpur" name="theme" />
              <label htmlFor="oneCallPurpur">OneCall Purpur</label>
            </div>
            <div style={{ display: 'flex' }}>
              <input type="radio" value="exampleOldCSS" name="theme" />
              <label htmlFor="exampleOldCSS">Example old CSS</label>
            </div>
          </fieldset>
        </div>
      </div>
      <section>
        <span>
          <label>Pre selected month</label>
          <input type="number" onChange={onPreSelectedMonthChange} />
        </span>

        <span style={{ display: 'flex', alignItems: 'center' }}>
          <label>Allow tracking</label>
          <input
            style={{ margin: '0 0 2px 10px' }}
            type="checkbox"
            onClick={onTrackingChange}
            checked={isTrackingAllowed}
          />
        </span>
      </section>
      <section className="order">
        <label htmlFor="order-textarea">Order</label>
        <textarea
          id="order-textarea"
          name="order-textarea"
          value={orderState}
          spellCheck="false"
          onChange={onOrderChange}
        />
        <button type="button" onClick={onInitiatePurchaseClicked}>
          Initiate purchase
        </button>
        <button type="button" onClick={onResetClicked}>
          Reset
        </button>
      </section>

      <section className="callbacks">
        <label htmlFor="callbacks-bin-host-input">Callbacks bin</label>
        <input
          type="text"
          id="callbacks-bin-host-input"
          name="callbacks-bin-host-input"
          placeholder="Enter a hostname for the bin"
          value={binHost}
          onChange={(event) => dispatch(setBinUrl(event.target.value))}
        />

        <label htmlFor="callbacks-bin-secret-token-input">Token</label>
        <input
          type="text"
          id="callbacks-bin-secret-token-input"
          name="callbacks-bin-secret-token-input"
          placeholder="Secret token"
          value={secretToken}
          onChange={(event) => setSecretToken(event.target.value)}
        />

        <label htmlFor="callbacks-bin-purchase-request-input">
          Purchase request token
        </label>
        <input
          type="text"
          id="callbacks-bin-purchase-request-input"
          name="callbacks-bin-purchase-request-input"
          placeholder="Enter a purchase request token"
          value={purchaseRequestToken}
          onChange={(event) =>
            dispatch(setPurchaseRequestToken(event.target.value))
          }
        />

        <button type="button" onClick={onFetchMessageFromBinClicked}>
          Fetch delivered callback message from bin
        </button>
        <button type="button" onClick={onFetchMessageFromServiceClicked}>
          Fetch callback message from service
        </button>
        <textarea className="message" value={retrievedCallbackMessage} />
      </section>
    </section>
  );
}

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { addErrorEvent, clear } from './consoleSlice';

/**
 * A console component showing messages and event
 */
// eslint-disable-next-line import/prefer-default-export
export function Console() {
  const dispatch = useDispatch();

  // Register a general error event listener on `window`. This will
  // allow us to see some errors related to script execution. For instance,
  // even if a script sucessfully loads, it might generate errors after
  // load, and those can show up here instead.
  useEffect(() => {
    const listener = (event) => {
      console.error(event);
      dispatch(
        addErrorEvent({ filename: event.filename, message: event.message })
      );
    };
    window.addEventListener('error', listener);
    return () => {
      window.removeEventListener('error', listener);
    };
  }, [dispatch]);

  const onClearClicked = () => dispatch(clear());

  const rows = useSelector((state) => state.console.rows);
  // Render a reversed copy of rows. This way the most recent rows are
  // shown at the top. This makes it easier to see them. (Another way
  // would be to not reverse, but keep the console scrolled to the
  // bottom).
  const renderedRows = rows
    .slice()
    .reverse()
    .map((row) => {
      switch (row.type) {
        case 'error-event': {
          const { message, filename } = row.value;
          return (
            <li key={row.id} className="error">
              {message} (<a href={filename}>{filename}</a>)
            </li>
          );
        }
        case 'webcheckout-event':
          return (
            <li key={row.id}>
              <span className="event-label">Webcheckout Event</span>
              {JSON.stringify(row.value, undefined, 4)}
            </li>
          );
        case 'message':
          return (
            <li key={row.id} className={row.isError ? 'error' : ''}>
              {row.value}
            </li>
          );
        default:
          return (
            <li key={row.id}>
              <em>Unknown row type</em>:<br />
              {JSON.stringify(row)}
            </li>
          );
      }
    });

  return (
    <section className="console">
      <h2>Messages</h2>
      <button type="button" className="clear-button" onClick={onClearClicked}>
        Clear
      </button>
      {renderedRows}
    </section>
  );
}

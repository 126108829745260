import { createSlice } from '@reduxjs/toolkit';
import {
  DEMO_PROD_BASE_URL,
  DEMO_PROD_BASE_URL_1_30,
  DEMO_PROD_HOST,
  DEMO_PROD_HOST_1_30,
  DEMO_STAGE_BASE_URL,
  DEMO_STAGE_BASE_URL_1_30,
  DEMO_STAGE_HOST,
  DEMO_STAGE_HOST_1_30,
  DEV_BIN_URL,
  DEV_BIN_URL_1_30,
  DEV_DEMO_BASE_URL,
  DEV_DEMO_BASE_URL_1_30,
  DEV_DEMO_HOST,
  DEV_DEMO_HOST_1_30,
  Environment,
  LOCAL_BASE_URL,
  PROD_BIN_URL,
  PROD_BIN_URL_1_30,
  QA_BIN_URL,
  QA_BIN_URL_1_30,
  QA_DEMO_BASE_URL,
  QA_DEMO_BASE_URL_1_30,
  QA_DEMO_HOST,
  QA_DEMO_HOST_1_30,
  STAGE_BIN_URL,
  STAGE_BIN_URL_1_30
} from '../constants/constants';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    environment: '',
    hostUrl: '',
    scriptUrl: '',
    binUrl: ''
  },
  reducers: {
    setEnvironment: {
      reducer(state, action) {
        state.environment = action.payload;
      }
    },
    setHostUrl: {
      reducer(state, action) {
        state.hostUrl = action.payload;
      }
    },
    setScriptUrl: {
      reducer(state, action) {
        state.scriptUrl = action.payload;
      }
    },
    setBinUrl: {
      reducer(state, action) {
        state.binUrl = action.payload;
      }
    }
  }
});

export const { setEnvironment, setHostUrl, setScriptUrl, setBinUrl } =
  appSlice.actions;

export default appSlice.reducer;

export const initializeEnvironment = () => {
  const host = window.location.hostname;

  switch (host) {
    case DEV_DEMO_HOST:
      return Environment.DEV;
    case DEV_DEMO_HOST_1_30:
      return Environment.DEV_1_30;
    case QA_DEMO_HOST:
      return Environment.QA;
    case QA_DEMO_HOST_1_30:
      return Environment.QA_1_30;
    case DEMO_STAGE_HOST:
      return Environment.STAGE;
    case DEMO_STAGE_HOST_1_30:
      return Environment.STAGE_1_30;
    case DEMO_PROD_HOST:
      return Environment.PROD;
    case DEMO_PROD_HOST_1_30:
      return Environment.PROD_1_30;
    default:
      return 'LOCAL';
  }
};

export const resolveHostUrl = (env) => (dispatch) => {
  switch (env) {
    case Environment.DEV:
      return dispatch(setHostUrl(DEV_DEMO_BASE_URL));
    case Environment.DEV_1_30:
      return dispatch(setHostUrl(DEV_DEMO_BASE_URL_1_30));
    case Environment.QA:
      return dispatch(setHostUrl(QA_DEMO_BASE_URL));
    case Environment.QA_1_30:
      return dispatch(setHostUrl(QA_DEMO_BASE_URL_1_30));
    case Environment.STAGE:
      return dispatch(setHostUrl(DEMO_STAGE_BASE_URL));
    case Environment.STAGE_1_30:
      return dispatch(setHostUrl(DEMO_STAGE_BASE_URL_1_30));
    case Environment.PROD:
      return dispatch(setHostUrl(DEMO_PROD_BASE_URL));
    case Environment.PROD_1_30:
      return dispatch(setHostUrl(DEMO_PROD_BASE_URL_1_30));
    default:
      return dispatch(setHostUrl(LOCAL_BASE_URL));
  }
};

export const resolveScriptUrl = (env) => (dispatch) => {
  switch (env) {
    case Environment.DEV:
      return dispatch(setScriptUrl(`${DEV_DEMO_BASE_URL}/sdk-v1.js`));
    case Environment.DEV_1_30:
      return dispatch(setScriptUrl(`${DEV_DEMO_BASE_URL_1_30}/sdk-v1.js`));
    case Environment.QA:
      return dispatch(setScriptUrl(`${QA_DEMO_BASE_URL}/sdk-v1.js`));
    case Environment.QA_1_30:
      return dispatch(setScriptUrl(`${QA_DEMO_BASE_URL_1_30}/sdk-v1.js`));
    case Environment.STAGE:
      return dispatch(setScriptUrl(`${DEMO_STAGE_BASE_URL}/sdk-v1.js`));
    case Environment.STAGE_1_30:
      return dispatch(setScriptUrl(`${DEMO_STAGE_BASE_URL_1_30}/sdk-v1.js`));
    case Environment.PROD:
      return dispatch(setScriptUrl(`${DEMO_PROD_BASE_URL}/sdk-v1.js`));
    case Environment.PROD_1_30:
      return dispatch(setScriptUrl(`${DEMO_PROD_BASE_URL_1_30}/sdk-v1.js`));
    default:
      return dispatch(setScriptUrl(`${LOCAL_BASE_URL}/sdk-v1.js`));
  }
};

export const resolveBinUrl = (env) => (dispatch) => {
  switch (env) {
    case Environment.DEV:
      return dispatch(setBinUrl(DEV_BIN_URL));
    case Environment.DEV_1_30:
      return dispatch(setBinUrl(DEV_BIN_URL_1_30));
    case Environment.QA:
      return dispatch(setBinUrl(QA_BIN_URL));
    case Environment.QA_1_30:
      return dispatch(setBinUrl(QA_BIN_URL_1_30));
    case Environment.STAGE:
      return dispatch(setBinUrl(STAGE_BIN_URL));
    case Environment.STAGE_1_30:
      return dispatch(setBinUrl(STAGE_BIN_URL_1_30));
    case Environment.PROD:
      return dispatch(setBinUrl(PROD_BIN_URL));
    case Environment.PROD_1_30:
      return dispatch(setBinUrl(PROD_BIN_URL_1_30));
    default:
      return dispatch(setBinUrl(DEV_BIN_URL));
  }
};

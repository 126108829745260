import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  initializeEnvironment,
  resolveBinUrl,
  resolveHostUrl,
  resolveScriptUrl,
  setEnvironment
} from '../../app/appSlice';

// eslint-disable-next-line react/prop-types
function StateInitializer({ children }) {
  const dispatch = useDispatch();
  useEffect(() => {
    const env = initializeEnvironment();

    dispatch(setEnvironment(env));
    dispatch(resolveHostUrl(env));
    dispatch(resolveScriptUrl(env));
    dispatch(resolveBinUrl(env));
  }, []);

  return <>{children}</>;
}

export default StateInitializer;

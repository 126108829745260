import { Merchant } from './features/merchant/Merchant';
import { Sdk } from './features/sdk/Sdk';
import { Console } from './features/console/Console';
import StateInitializer from './features/initializer/StateInitializer';

export default function App() {
  return (
    <StateInitializer>
      <div className="app">
        <Merchant />
        <div className="sdk-flex-wrapper">
          <Sdk />
        </div>
        <Console />
      </div>
    </StateInitializer>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import { setPurchaseRequestToken as setPurchaseRequestTokenForSdk } from '../sdk/sdkSlice';
import { addMessage } from '../console/consoleSlice';

export const merchantSlice = createSlice({
  name: 'merchant',
  initialState: {
    purchaseRequestToken: '',
    retrievedCallbackMessage: '',
    customCssUrl: '',
    preSelectedMonth: null,
    isTrackingAllowed: true
  },
  reducers: {
    setPurchaseRequestToken: {
      reducer(state, action) {
        state.purchaseRequestToken = action.payload;
      }
    },
    setRetrievedCallbackMessage: {
      reducer(state, action) {
        state.retrievedCallbackMessage = action.payload;
      }
    },
    setCustomCssUrl: {
      reducer(state, action) {
        state.customCssUrl = action.payload;
      }
    },
    setPreSelectedMonth: {
      reducer(state, action) {
        state.preSelectedMonth = action.payload;
      }
    },
    setIsTrackingAllowed: {
      reducer(state, action) {
        state.isTrackingAllowed = action.payload;
      }
    }
  }
});

export const {
  setRetrievedCallbackMessage,
  setPurchaseRequestToken,
  setCustomCssUrl,
  setPreSelectedMonth,
  setIsTrackingAllowed
} = merchantSlice.actions;
export default merchantSlice.reducer;

const generateBase64Token = ({ username, password }) => {
  if (!username || !password) return;

  // eslint-disable-next-line consistent-return
  return `Basic ${window.btoa(`${username}:${password}`)}`;
};
export const initiatePurchase =
  (host, payload, basicCredentials, authToken) => async (dispatch) => {
    const tokenWithPrefix = authToken || generateBase64Token(basicCredentials);

    dispatch(addMessage('Sending initiate request...'));

    const response = await fetch(`${host}/v1/payment/initiate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(tokenWithPrefix && { Authorization: tokenWithPrefix })
      },
      body: payload
    });

    if (response.status !== 201) {
      try {
        const r = await response.json();
        console.error(r);
        dispatch(
          addMessage(
            `Initiate request failed with error:\n${JSON.stringify(
              r,
              null,
              4
            )}`,
            true /* isError */
          )
        );
      } catch (e) {
        console.error(response);
        dispatch(
          addMessage(
            `Initiate request failed: ${response.statusText}`,
            true /* isError */
          )
        );
      }
      return;
    }

    const token = (await response.json()).purchaseRequestToken;
    if (token === undefined || token === null) {
      dispatch(addMessage('Found no token in response', true /* isError */));
    } else {
      dispatch(
        addMessage(
          `Successfully initiated purchase.\nReceived token "${token}"`
        )
      );
      dispatch(setPurchaseRequestTokenForSdk(token));
      dispatch(setPurchaseRequestToken(token));
    }
  };

export const fetchCallbackMessageFromBin =
  (binHost, secretToken, purchaseRequestToken) => async (dispatch) => {
    dispatch(addMessage(`Fetching callback message from ${binHost}...`));

    const response = await fetch(
      `${binHost}/messages/${purchaseRequestToken}`,
      {
        method: 'GET',
        headers: { Authorization: `Bearer ${secretToken}` }
      }
    );

    if (response.status === 404) {
      dispatch(
        addMessage(`No callback message found for "${purchaseRequestToken}"`)
      );
      return;
    }

    if (response.status !== 200) {
      dispatch(
        addMessage(
          `Failed to fetch from callbacks bin: ${response.statusText}`,
          true /* is Error */
        )
      );
      return;
    }

    const message = await response.json();
    const jsonString = JSON.stringify(message, undefined, 2);
    dispatch(addMessage(`Successfully fetched callback message`));
    dispatch(setRetrievedCallbackMessage(jsonString));
  };

export const fetchCallbackMessageFromService =
  (host, purchaseRequestToken, basicCredentials, authToken) =>
  async (dispatch) => {
    dispatch(addMessage(`Fetching callback message from ${host} ...`));

    const tokenWithPrefix = authToken || generateBase64Token(basicCredentials);

    const response = await fetch(
      `${host}/v1/payment/result/${purchaseRequestToken}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(tokenWithPrefix && { Authorization: tokenWithPrefix })
        }
      }
    );

    if (response.status === 404) {
      dispatch(
        addMessage(`No callback message found for "${purchaseRequestToken}"`)
      );
      return;
    }

    if (response.status !== 200) {
      dispatch(
        addMessage(
          `Failed to fetch message from service: ${response.statusText}`,
          true /* is Error */
        )
      );
      return;
    }

    const message = await response.json();
    const jsonString = JSON.stringify(message, undefined, 2);
    dispatch(addMessage(`Successfully fetched callback message`));
    dispatch(setRetrievedCallbackMessage(jsonString));
  };

import { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { initiate, loadScript, setPurchaseRequestToken } from './sdkSlice';

import { addMessage, addErrorEvent } from '../console/consoleSlice';
import { setScriptUrl } from '../../app/appSlice';

/**
 * A component for loading and initiating the webcheckut SDK flow in a
 * target <iframe>.
 */
// eslint-disable-next-line import/prefer-default-export
export function Sdk() {
  const loadStatus = useSelector((state) => state.sdk.status);
  const purchaseRequestToken = useSelector(
    (state) => state.sdk.purchaseRequestToken
  );

  const { preSelectedMonth, customCssUrl, isTrackingAllowed } = useSelector(
    (state) => state.merchant
  );

  const dispatch = useDispatch();

  const url = useSelector((state) => state.app.scriptUrl);

  const webcheckoutFrame = useRef(null);
  const initiateWebcheckout = () => {
    if (loadStatus === 'succeeded') {
      dispatch(
        initiate({
          targetElement: webcheckoutFrame.current,
          purchaseRequestToken,
          preSelectedMonth,
          customCssUrl,
          isTrackingAllowed
        })
      );
    }
  };

  const onLoadScriptClicked = async () => {
    try {
      await dispatch(loadScript(url)).unwrap();
      dispatch(addMessage(`Loaded script "${url}"`));
    } catch (err) {
      console.error('Script failed to load');
      dispatch(
        addErrorEvent({ filename: url, message: 'Script failed to load' })
      );
    }
  };
  const callOnEnter = (fn) => (event) => {
    if (event.code === 'Enter') fn();
  };

  return (
    <section className="sdk">
      <h2>Webcheckout</h2>
      <label htmlFor="sdk-src-input">SDK script source</label>
      <input
        type="text"
        id="sdk-src-input"
        name="sdk-src-input"
        placeholder="Enter a path to sdk.js"
        value={url}
        onChange={(event) => dispatch(setScriptUrl(event.target.value))}
        onKeyPress={callOnEnter(onLoadScriptClicked)}
      />

      <button
        type="button"
        className="action-button"
        disabled={loadStatus === 'loading'}
        onClick={onLoadScriptClicked}
      >
        {loadStatus === 'loading' ? 'Loading... ' : 'Load script'}
      </button>

      <label htmlFor="purchase-request-input">Purchase request token</label>
      <input
        type="text"
        id="purchase-request-input"
        name="purchase-request-input"
        placeholder="Enter a purchase request token"
        value={purchaseRequestToken}
        onChange={(event) =>
          dispatch(setPurchaseRequestToken(event.target.value))
        }
        onKeyPress={callOnEnter(initiateWebcheckout)}
      />
      <br />
      <button
        type="button"
        disabled={loadStatus !== 'succeeded'}
        title={
          loadStatus !== 'succeeded'
            ? 'No SDK script loaded'
            : 'Click to load iframe with purchase request token'
        }
        onClick={initiateWebcheckout}
      >
        Initiate webcheckout
      </button>

      <div className="iframe-target" ref={webcheckoutFrame}>
        <div id="webcheckout-wrapper">
          <div className="iframe-placeholder">
            <em>&lt;iframe&gt;</em>
          </div>
        </div>
      </div>
    </section>
  );
}

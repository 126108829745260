import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { addWebcheckoutEvent, addMessage } from '../console/consoleSlice';

export const loadScript = createAsyncThunk('sdk/loadScript', (url) => {
  // Load the SDK by dynamically adding a <script> tag to the page.
  // If there's already a script loaded, remove it first.
  const loadedScript = document.getElementById('sdk-script');

  if (loadedScript) {
    loadedScript.remove();
  }

  const script = document.createElement('script');
  script.id = 'sdk-script';
  script.src = url;
  script.defer = true;

  return new Promise((resolve, reject) => {
    script.onload = function () {
      resolve();
    };
    script.onerror = function (e) {
      console.error(e);
      reject();
    };
    document.head.appendChild(script);
  });
});

export const sdkSlice = createSlice({
  name: 'sdk',
  initialState: {
    status: 'idle',
    purchaseRequestToken: ''
  },
  reducers: {
    setPurchaseRequestToken: {
      reducer(state, action) {
        state.purchaseRequestToken = action.payload;
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loadScript.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadScript.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(loadScript.rejected, (state) => {
        state.status = 'failed';
      });
  }
});

export const { setPurchaseRequestToken } = sdkSlice.actions;
export default sdkSlice.reducer;

export const initiate =
  ({
    targetElement,
    purchaseRequestToken,
    preSelectedMonth,
    customCssUrl,
    isTrackingAllowed
  }) =>
  async (dispatch) => {
    dispatch(addMessage('Initiating webcheckout...'));
    let webcheckoutApi;
    try {
      webcheckoutApi = await window.initWebcheckout({
        element: targetElement,
        purchaseRequestToken,
        customCssUrl,
        preSelectedMonth,
        cookieConsent: {
          allowStatisticalCookies: isTrackingAllowed
        }
      });
      dispatch(addMessage('Webcheckout initiated. Invoking API start...'));
    } catch (err) {
      console.error(err);
      dispatch(
        addMessage(
          `initWebcheckout() failed with error:\n${JSON.stringify(
            err,
            null,
            4
          )}`,
          true /* isError */
        )
      );
      return;
    }

    // For any event emitted from the Webcheckout, dispatch it as
    // an event to the console.
    webcheckoutApi.on('*', (event) => dispatch(addWebcheckoutEvent(event)));

    try {
      const lastEvent = await webcheckoutApi.start();
      dispatch(
        addMessage(
          `Webcheckout finished with event:\n${JSON.stringify(
            lastEvent,
            null,
            4
          )}`
        )
      );
    } catch (err) {
      console.log(err);
      dispatch(
        addMessage(
          `Webcheckout failed with error:\n${JSON.stringify(err, null, 4)}`,
          true /* isError */
        )
      );
      console.error(err);
    }
  };

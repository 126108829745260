import { createSlice, nanoid } from '@reduxjs/toolkit';

/**
 * The console supports different types rows that can be added to it.
 *
 * - messages -- Normal text messages (can be marked as errors)
 * - webcheckout-events -- Events emitted from the Webcheckout SDK
 * - error-events -- Error events from the `window` object
 *
 * This slice takes care of handling the actions of adding these
 * entities, and put them into a list of rows. They are then rendered
 * appropriately in the Console component.
 */
export const consoleSlice = createSlice({
  name: 'events',
  initialState: {
    rows: []
  },
  reducers: {
    addWebcheckoutEvent: {
      reducer(state, action) {
        state.rows.push(action.payload);
      },
      prepare(event) {
        return {
          payload: {
            id: nanoid(),
            type: 'webcheckout-event',
            value: event
          }
        };
      }
    },
    addErrorEvent: {
      reducer(state, action) {
        state.rows.push(action.payload);
      },
      prepare(event) {
        return {
          payload: {
            id: nanoid(),
            type: 'error-event',
            value: event
          }
        };
      }
    },
    addMessage: {
      reducer(state, action) {
        state.rows.push(action.payload);
      },
      prepare(message, isError) {
        return {
          payload: {
            id: nanoid(),
            type: 'message',
            value: message,
            isError
          }
        };
      }
    },
    clear: (state) => {
      state.rows = [];
    }
  }
});

export const { addWebcheckoutEvent, addErrorEvent, addMessage, clear } =
  consoleSlice.actions;

export default consoleSlice.reducer;

import { useEffect, useState } from 'react';

const useLocalStorage = (localStorageKey, defaultValue = '') => {
  const [storedValue, setStoredValue] = useState(() => {
    const item = localStorage.getItem(localStorageKey);
    if (item) {
      return JSON.parse(item);
    }
    return defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(storedValue));
  }, [localStorageKey, storedValue]);

  return [storedValue, setStoredValue];
};

export default useLocalStorage;

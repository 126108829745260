import { configureStore } from '@reduxjs/toolkit';
import merchantReducer from '../features/merchant/merchantSlice';
import consoleReducer from '../features/console/consoleSlice';
import appReducer from './appSlice';
import sdkReducer from '../features/sdk/sdkSlice';

// eslint-disable-next-line import/prefer-default-export
export const store = configureStore({
  reducer: {
    app: appReducer,
    merchant: merchantReducer,
    sdk: sdkReducer,
    console: consoleReducer
  }
});
